import React from "react";
import {
  Modal,
  Box,
  Typography,
  Fade,
  Backdrop,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LeaveBalance from "../../../src/layouts/leave/LeaveBalance";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 390, // Make the modal smaller
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
};

export default function LeaveSummaryModal({ open, onClose }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {/* Close Icon */}
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "grey.700",
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          {/* Modal Title */}
          <Typography variant="h6" component="h2" mb={2}>
            Leave Summary
          </Typography>
          {/* Modal Content */}
          <LeaveBalance />
        </Box>
      </Fade>
    </Modal>
  );
}
