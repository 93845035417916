import React, { useState } from "react";
import { Box, Button, Paper } from "@mui/material";
import IwoApp from "./IwoApp";
import EwoApp from "./EwoApp";
import MocApp from "./MocApp";
import UmrApp from "./UmrApp";

const Approvals = () => {
  const [selectedTab, setSelectedTab] = useState("IwoApp");

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const renderComponent = () => {
    switch (selectedTab) {
      case "IwoApp":
        return <IwoApp />;
      case "EwoApp":
        return <EwoApp />;
      case "MocApp":
        return <MocApp />;
      case "UmrApp":
        return <UmrApp />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/* Tab Buttons */}
      <Box
        sx={{
          display: "flex",
          gap: 0,
          marginTop: 2,
        }}
      >
        <Button
          variant={selectedTab === "IwoApp" ? "contained" : "outlined"}
          onClick={() => handleTabChange("IwoApp")}
          sx={{
            borderRadius: "8px 0 0 8px",
            backgroundColor:
              selectedTab === "IwoApp" ? "#5ac8fa" : "transparent",
            "&:hover": {
              backgroundColor: selectedTab === "IwoApp" ? "#5ac8fa" : "#f1f1f1",
            },
            padding: "14px 34px", // Increase padding for larger buttons
            fontSize: "16px", // Increase font size for better readability
          }}
        >
          IWO
        </Button>
        <Button
          variant={selectedTab === "EwoApp" ? "contained" : "outlined"}
          onClick={() => handleTabChange("EwoApp")}
          sx={{
            borderRadius: 0,
            backgroundColor:
              selectedTab === "EwoApp" ? "#5ac8fa" : "transparent",
            "&:hover": {
              backgroundColor: selectedTab === "EwoApp" ? "#5ac8fa" : "#f1f1f1",
            },
            padding: "14px 32px", // Increase padding for larger buttons
            fontSize: "16px", // Increase font size for better readability
          }}
        >
          EWO
        </Button>
        <Button
          variant={selectedTab === "MocApp" ? "contained" : "outlined"}
          onClick={() => handleTabChange("MocApp")}
          sx={{
            borderRadius: 0,
            backgroundColor:
              selectedTab === "MocApp" ? "#5ac8fa" : "transparent",
            "&:hover": {
              backgroundColor: selectedTab === "MocApp" ? "#5ac8fa" : "#f1f1f1",
            },
            padding: "14px 32px", // Increase padding for larger buttons
            fontSize: "16px", // Increase font size for better readability
          }}
        >
          MOC
        </Button>
        <Button
          variant={selectedTab === "UmrApp" ? "contained" : "outlined"}
          onClick={() => handleTabChange("UmrApp")}
          sx={{
            borderRadius: "0 8px 8px 0",
            backgroundColor:
              selectedTab === "UmrApp" ? "#5ac8fa" : "transparent",
            "&:hover": {
              backgroundColor: selectedTab === "UmrApp" ? "#5ac8fa" : "#f1f1f1",
            },
            padding: "14px 34px", // Increase padding for larger buttons
            fontSize: "16px", // Increase font size for better readability
          }}
        >
          UMR
        </Button>
      </Box>

      {/* Render Selected Component */}

      <Paper elevation={1} sx={{ padding: 1, marginTop: 2 }}>
        <Box sx={{ marginTop: 2 }}>{renderComponent()}</Box>
      </Paper>
    </Box>
  );
};

export default Approvals;
