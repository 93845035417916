import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import { useTheme } from "@material-ui/core/styles";
import LeaveBalance from "./LeaveBalance";
import Punctuality from "./Punctuality";
import NotEnteredLeave from "./NotEnteredLeave";
import LeaveSummery from "./LeaveSummery";
import {
  GetLeaveBalance,
  GetLeaveSummary,
  GetNotEnteredLeave,
  GetPunctuality,
} from "../../action/Leave";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import LeaveDetailsModal from "../../../src/components/Utility/LeaveDetailsModal";
import LeaveSummaryModal from "../../../src/components/Utility/LeaveSummaryModal";
import { Visibility, DesignServices } from "@mui/icons-material";
import Swal from "sweetalert2";
import axios from "axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Leave = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [year, setYear] = React.useState(dayjs().year());
  const [selectedRow, setSelectedRow] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openLeaveSummaryModal, setOpenLeaveSummaryModal] = useState(false);
  const [selectedTab, setSelectedTab] = React.useState("LeaveSummery");
  const [modalTitle, setModalTitle] = useState("");
  const [leaveDetailsData, setLeaveDetailsData] = useState([]);
  const [showLeaveBalanceSummary, setShowLeaveBalanceSummary] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const leaveBalanceData = useSelector(
    (state) => state.leaveBalance.responseBody
  );
  const punctualityData = useSelector(
    (state) => state.punctuality.responseBody
  ); // Replace with correct selector
  const notEnteredLeaveData = useSelector(
    (state) => state.notEnteredLeave.responseBody
  ); // Replace with correct selector
  const isLoading = useSelector((state) => state.leaveBalance.loading);
  const maxYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => maxYear - index);

  const handleLeaveSummaryClick = () => {
    setSelectedTab("LeaveSummery");
    setShowLeaveBalanceSummary(true);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedTab(event.target.value);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };
  const handleDesignButtonClick = () => {
    setOpenLeaveSummaryModal(true); // Open the LeaveSummaryModal when clicked
  };

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);

    // Check for no data
    let data = [];
    switch (tabName) {
      case "LeaveSummery":
        data = leaveBalanceData;
        break;
      case "NotEnteredLeave":
        data = notEnteredLeaveData;
        break;
      case "Punctuality":
        data = punctualityData;
        break;
      default:
        data = [];
    }

    if (data.length === 0) {
      Swal.fire({
        title: "No Data Available",
        text: `No data available for the ${tabName} tab for the selected Year.`,
        icon: "info",
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    dispatch(GetLeaveBalance(year));
    dispatch(GetNotEnteredLeave(year));
    dispatch(GetPunctuality(year));
    dispatch(GetLeaveSummary(year));
  }, [dispatch, year]);

  const totalLeave = leaveBalanceData.reduce(
    (sum, row) => sum + (parseFloat(row.Total) || 0),
    0
  );
  const totalTakenLeave = leaveBalanceData.reduce(
    (sum, row) => sum + (parseFloat(row.Taken) || 0),
    0
  );
  const totalBalanceLeave = leaveBalanceData.reduce(
    (sum, row) => sum + (parseFloat(row.Balance) || 0),
    0
  );
  const fetchLeaveDetails = async (P_TYPE, P_YEAR) => {
    try {
      const response = await axios.get(`Leave/GetLeaveByType`, {
        params: {
          P_YEAR: P_YEAR,
          P_TYPE: P_TYPE,
        },
      });
      if (response.data && response.data.StatusCode === 200) {
        setLeaveDetailsData(response.data.ResultSet || []);
      } else {
        setLeaveDetailsData([]);
        console.error("Failed to fetch leave details:", response.data);
      }
    } catch (error) {
      console.error("Error fetching leave details:", error);
      setLeaveDetailsData([]);
    }
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/* Table for Leave Data */}
      <FormControl
        sx={{
          minWidth: 130,
          width: "100px",
          mt: 1,
          mb: 1,
          "& .MuiSelect-root": {
            height: "32px",
            fontSize: "12px",
          },
          "& .MuiInputLabel-root": {
            fontSize: "12px",
            top: "4px",
          },
        }}
      >
        <InputLabel>Year</InputLabel>
        <Select
          value={year}
          onChange={handleChangeYear}
          label="Year"
          size="small"
          sx={{
            height: "32px",
            fontSize: "12px",
          }}
        >
          <MenuItem value={2025}>2025</MenuItem>
          <MenuItem value={2024}>2024</MenuItem>
          <MenuItem value={2023}>2023</MenuItem>
          <MenuItem value={2022}>2022</MenuItem>
          <MenuItem value={2021}>2021</MenuItem>
        </Select>
      </FormControl>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 0,
        }}
      >
        <Button
          variant={selectedTab === "LeaveSummery" ? "contained" : "outlined"}
          // onClick={handleLeaveSummaryClick}
          onClick={() => handleTabClick("LeaveSummery")}
          sx={{
            //borderRadius: "8px 0 0 8px",
            backgroundColor:
              selectedTab === "LeaveSummery" ? "#5ac8fa" : "transparent",
            "&:hover": {
              backgroundColor:
                selectedTab === "LeaveSummery" ? "#5ac8fa" : "#f1f1f1",
            },
          }}
        >
          Leave Summary
        </Button>
        <Button
          variant={selectedTab === "NotEnteredLeave" ? "contained" : "outlined"}
          // onClick={() => setSelectedTab("NotEnteredLeave")}
          onClick={() => handleTabClick("NotEnteredLeave")}
          sx={{
            //borderRadius: 0,
            backgroundColor:
              selectedTab === "NotEnteredLeave" ? "#5ac8fa" : "transparent",
            "&:hover": {
              backgroundColor:
                selectedTab === "NotEnteredLeave" ? "#5ac8fa" : "#f1f1f1",
            },
          }}
        >
          Not Entered Leave
        </Button>
        <Button
          variant={selectedTab === "Punctuality" ? "contained" : "outlined"}
          // onClick={() => setSelectedTab("Punctuality")}
          onClick={() => handleTabClick("Punctuality")}
          sx={{
            //borderRadius: "0 8px 8px 0",
            backgroundColor:
              selectedTab === "Punctuality" ? "#5ac8fa" : "transparent",
            "&:hover": {
              backgroundColor:
                selectedTab === "Punctuality" ? "#5ac8fa" : "#f1f1f1",
            },
          }}
        >
          Punctuality
        </Button>
      </Box>
      {showLeaveBalanceSummary && (
        <Paper elevation={1} sx={{ padding: 1, marginTop: 2 }}>
          <Typography variant="h6" sx={{ marginBottom: 1, fontWeight: "bold" }}>
            Leave Balance Summary
          </Typography>
          {isLoading ? (
            <Typography>Loading...</Typography>
          ) : (
            <Table size="small" sx={{ width: "100%", tableLayout: "fixed" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#1976d2", color: "white" }}>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "white",
                      padding: "8px", // Adjust padding for all columns
                    }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "white",
                      textAlign: "right",
                      padding: "8px", // Adjust padding for the Total column
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "white",
                      textAlign: "right",
                      padding: "8px", // Adjust padding for the Taken column
                    }}
                  >
                    Taken
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "white",
                      textAlign: "right",
                      padding: "8px", // Adjust padding for the Balance column
                    }}
                  >
                    Balance
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "white",
                      textAlign: "center",
                      padding: "8px", // Adjust padding for the Action button column
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leaveBalanceData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.Description}</TableCell>
                    <TableCell sx={{ textAlign: "right", padding: "8px" }}>
                      {row.Total}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right", padding: "8px" }}>
                      {row.Taken}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right", padding: "8px" }}>
                      {row.Balance}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", padding: "8px" }}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setModalTitle(`${row.Description} Details`);
                          fetchLeaveDetails(row.Type, year);
                          setOpenModal(true);
                        }}
                        sx={{
                          backgroundColor: "#5ac8fa",
                          "&:hover": { backgroundColor: "#5ac8fa" },
                          color: "white",
                          borderColor: "#5ac8fa",
                          padding: "4px",
                          minWidth: "auto",
                          borderRadius: "50%",
                        }}
                      >
                        <Visibility sx={{ fontSize: "16px" }} />{" "}
                        {/* Smaller icon size */}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Total</TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      textAlign: "right",
                      padding: "8px",
                    }}
                  >
                    {totalLeave}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      textAlign: "right",
                      padding: "8px",
                    }}
                  >
                    {totalTakenLeave}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      textAlign: "right",
                      padding: "8px",
                    }}
                  >
                    {totalBalanceLeave}
                  </TableCell>
                  <TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={handleDesignButtonClick}
                        sx={{
                          backgroundColor: "#5ac8fa",
                          "&:hover": { backgroundColor: "#5ac8fa" },
                          color: "white",
                          borderColor: "#5ac8fa",
                          padding: "4px",
                          minWidth: "auto",
                          borderRadius: "50%",
                          marginLeft: "-5px",
                        }}
                      >
                        <DesignServices sx={{ fontSize: "16px" }} />
                      </Button>
                    </TableCell>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Paper>
      )}
      <Box
        flex={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          mt: 1,
          mb: 1,
          mr: 1,
        }}
      ></Box>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          {selectedTab === "LeaveBalance" && "Leave Balance Summary"}
          {selectedTab === "Punctuality" && "Punctuality Summary"}
          {selectedTab === "NotEnteredLeave" && "Not Entered Leave Summary"}
          {selectedTab === "LeaveSummery" && "Leave Summary"}
        </Typography>
        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : (
          <>
            {selectedTab === "LeaveBalance" && <LeaveBalance />}
            {selectedTab === "Punctuality" && <Punctuality />}
            {selectedTab === "NotEnteredLeave" && (
              <NotEnteredLeave selectedYear={year} />
            )}
            {selectedTab === "LeaveSummery" && <LeaveSummery />}
          </>
        )}
      </Paper>
      {openLeaveSummaryModal && (
        <LeaveSummaryModal
          open={openLeaveSummaryModal}
          onClose={() => setOpenLeaveSummaryModal(false)}
        />
      )}

      {openModal && (
        <LeaveDetailsModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          rowData={leaveDetailsData}
          modalTitle={modalTitle}
        />
      )}
    </Box>
  );
};

export default Leave;
