import React, { useEffect, useState } from "react";
import { Box, Button, Paper,MenuItem,FormControl,InputLabel,Select} from "@mui/material";
import { useTheme } from "@material-ui/core/styles";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  GetMedicalIndoorUsageDetails,
  GetMedicalOutdoorUsageDetails,
  GetUserMedicalDetails,
} from "../../action/Medical";
import IndoorAllocations from "./indoorAllocations";

const Leave = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("IndoorAllocations");
  const [year, setYear] = useState(dayjs().format("YYYY"));

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const renderComponent = () => {
    switch (selectedTab) {
      case "IndoorAllocations":
        return <IndoorAllocations allocationName="Indoor" />;
      case "OutdoorAllocations":
        return <IndoorAllocations allocationName="Outdoor" />;
      default:
        return null;
    }
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", "#004AAD");
    }
    dispatch(GetUserMedicalDetails(year));
    dispatch(GetMedicalIndoorUsageDetails(year));
    dispatch(GetMedicalOutdoorUsageDetails(year));
  }, [year]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FormControl
        sx={{
          minWidth: 130,
          width: "100px",
          mt: 1,
          mb: 1,
          "& .MuiSelect-root": {
            height: "32px",
            fontSize: "12px",
          },
          "& .MuiInputLabel-root": {
            fontSize: "12px",
            top: "2px",
          },
        }}
      >
        <InputLabel>Year</InputLabel>
        <Select
          value={year}
          onChange={handleChangeYear}
          label="Year"
          size="small"
          sx={{
            height: "32px",
            fontSize: "12px",
          }}
        >
          <MenuItem value={2025}>2025</MenuItem>
          <MenuItem value={2024}>2024</MenuItem>
          <MenuItem value={2023}>2023</MenuItem>
          <MenuItem value={2022}>2022</MenuItem>
          <MenuItem value={2021}>2021</MenuItem>
        </Select>
      </FormControl>

      <Box
        id={"header"}
        sx={{
          position: "sticky",
          top: 0,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", mt: 1, mb: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              padding: 1,
            }}
          >
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#F2F2F2",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  //gap: 2,
                  justifyContent: "center",
                  marginBottom: 2,
                }}
              >
                <Button
                  variant={selectedTab === "IndoorAllocations" ? "contained" : "outlined"}
                  onClick={() => handleTabChange("IndoorAllocations")}
                  sx={{
                    backgroundColor: selectedTab === "IndoorAllocations" ? "#5ac8fa" : "transparent",
                    "&:hover": {
                      backgroundColor: selectedTab === "IndoorAllocations" ? "#5ac8fa" : "#f1f1f1",
                    },
                    padding: "14px 34px",
                    fontSize: "16px",
                  }}
                >
                  INDOOR ALLOCATIONS
                </Button>

                <Button
                  variant={selectedTab === "OutdoorAllocations" ? "contained" : "outlined"}
                  onClick={() => handleTabChange("OutdoorAllocations")}
                  sx={{
                    backgroundColor: selectedTab === "OutdoorAllocations" ? "#5ac8fa" : "transparent",
                    "&:hover": {
                      backgroundColor: selectedTab === "OutdoorAllocations" ? "#5ac8fa" : "#f1f1f1",
                    },
                    padding: "14px 34px",
                    fontSize: "16px",
                  }}
                >
                  OUTDOOR ALLOCATIONS
                </Button>
              </Box>

              {/* Render Selected Component */}
              <Paper elevation={1} sx={{ padding: 1, marginTop: 2 }}>
                <Box sx={{ marginTop: 2 }}>{renderComponent()}</Box>
              </Paper>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Leave;
